import { HStack, IconButton, Image, Tooltip } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { DICTIONARY, SC_ICONS_SRC } from 'src/constants';
import { getAppLink } from 'src/features/common/config';
import { UrlParams, useUrlSharing } from 'src/features/common/hooks/useUrlSharing';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export const ApplicationLinks = () => {
  const { urlContext } = useUrlSharing(UrlParams.tenantName | UrlParams.organization);
  const organization = urlContext.organization || '';
  const tenantName = urlContext.tenantName || '';
  const { environmentLabel } = useXMTenant();
  const onHomeIconClick = () => {
    window.location.href = encodeURI(getAppLink({ app: 'xmapps', params: { organization, tenantName }, isNew: true }));
  };

  const xmcPocketNav = `<xmc-pocket-nav environment="${environmentLabel}" tenant="${tenantName}" organization="${organization}" />`;

  return (
    <HStack gap="4">
      <HStack gap="1">
        <Tooltip label={<T _str={DICTIONARY.GO_TO_XM_CLOUD} />}>
          <IconButton
            icon={<Image data-testid="explorer-logo" h="6" w="auto" src={SC_ICONS_SRC.LOGO} />}
            variant="ghost"
            size="sm"
            aria-label=""
            data-testid="home-icon"
            onClick={onHomeIconClick}
          />
        </Tooltip>
        <div dangerouslySetInnerHTML={{ __html: xmcPocketNav }} />
      </HStack>
    </HStack>
  );
};
