import { FlexProps, Text } from '@chakra-ui/react';
import { memo } from 'react';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';

export const AnnotationDetails = memo((flexProps: FlexProps) => {
  const { projectName, environmentName } = useXMTenant();

  return (
    <Text>
      {projectName} / {environmentName}
    </Text>
  );
});

AnnotationDetails.displayName = AnnotationDetails.name;
